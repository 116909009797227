/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

import flex from "../styles/flex"

const Main = ({ children }) => (
  <main
    style={{
      ...flex.vertical,
      ...flex.centerHorizontalH,
    }}
  >
    {children}
  </main>
)

export default Main
