import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"

import rem from "../utils/rem"
import Text from "./text"
import flex from "../styles/flex"
import { breakpoints, smaller } from "../styles/responsive"


const Arrow = styled("span")`
  border: solid ${props => props.color};
  border-width: 0 2px 2px 0;
  display: block;
  padding: ${rem(4)};
  transform: rotate(45deg);
  position: absolute;
`

const Bounce = styled("span")`
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
  }
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: bounce;
`

const DoubleArrow = ({ color }) => (
  <Bounce
    style={{
      position: "relative",
      ...flex.vertical,
      top: rem(3),
      margin: `0 ${rem(20)}`,
      width: rem(15),
      height: rem(30),
      ...flex.centerHorizontalV,
    }}
  >
    <Arrow
      color={color}
      style={{
        opacity: 0.5,
      }}
    />
    <Arrow color={color} style={{ top: rem(15) }} />
  </Bounce>
)

const ScrollIndicator = ({ direction, color, inline, goTo = "", scrollDownText }) => (
  <a
    href={goTo}
    css={{
      textDecoration: "none",
      ...(inline
        ? { marginTop: rem(-50), marginLeft: rem(-800) }
        : { marginTop: rem(25) }),
      [smaller(breakpoints.xlg)]: {
        ...(inline ? { marginLeft: rem(-625) } : {}),
      },
      [smaller(breakpoints.lg)]: {
        ...(inline ? { marginLeft: rem(-525) } : {}),
      },
      [smaller(breakpoints.sm)]: {
        display: "none",
      },
    }}
  >
    <Text
      size="xsm"
      tag="span"
      style={{
        color,
        width: "100%",
        ...flex.horizontal,
        ...flex.centerHorizontal,
        ...(direction === "right" && flex.horizontalReverse),
        alignItems: "flex-start",
      }}
    >
      <span>{scrollDownText}</span>
      <DoubleArrow color={color} />
    </Text>
  </a>
)

const Hero = ({ children, scrollDownText = "Scroll Down to \n Learn more", type, color, inline, nextId = "", ...rest }) => (
  <div {...rest}>
    {children}
    <ScrollIndicator
      direction={type === "odd" ? "left" : "right"}
      color={color}
      inline={inline}
      goTo={`#${nextId}`}
      scrollDownText={scrollDownText}
    />
  </div>
)

Hero.defaultProps = {
  type: "odd",
  inline: false,
  color: "#4D5F6E",
}
Hero.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["odd", "even"]),
  inline: PropTypes.bool,
  color: PropTypes.string,
  id: PropTypes.string,
  nextId: PropTypes.string,
}

export default styled(Hero)({
  minHeight: "100vh",
  width: "100%",
  overflow: "hidden",
  padding: `${rem(90)} ${rem(50)}`,
  position: "relative",
  ...flex.vertical,
  ...flex.centerVertical,
  [smaller(breakpoints.sm)]: {
    padding: `${rem(60)} 0`,
    minHeight: 0,
  },
})
