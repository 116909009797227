import styled from "@emotion/styled"
import rem from "../utils/rem"
import { breakpoints, smaller } from "../styles/responsive"

const Columns = styled("div")(({ count }) => ({
  columnCount: count,
  columnGap: rem(125),
  [smaller(breakpoints.sm)]: {
    columnCount: 1,
  },
}))

export default Columns
